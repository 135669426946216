import React, { useEffect, useState, useRef } from "react";
import { SVGS } from "../../assets/svgs";
import { Button, CancelButton } from "../button/button";
import { ContentsContainer } from "../card/cardcontainer";
import {
  InputDate,
  InputSelect,
  InputText,
  InputText2,
} from "../inputs/inputs";
import { LightText } from "../lightText/lighttext";
import { BoldText, NormalText } from "../normalText/normalText";
import { PayableLogin, PayableScan } from "../payable/payable";
import styles from "./wallet.module.css";
import WayaBankLogo from "../../assets/wayabank-logo.png";
import axios from "axios";
import config from "../../config.js";
import qrPhoneIcon from "../../assets/qr-phone-icon.png";
import { ToastContainer, toast } from "react-toast";
import Select from "react-select";
import { convertTime } from "../../utils/helper";
import { authOTP } from "../../utils/auth";
import { ModalContainer } from "../modalContainer/container";
import { useSearchParams } from "react-router-dom";

const { qr_code } = SVGS;

const Spinner = () => {
  return <div className="spinner"></div>;
};

const supportedAccounts = [
  // {label: "Click here to select account provider", value: ""},
  { label: "Waya Bank", value: "wayabank" },
  // {label: "Guarantee Trust Bank (G.T.B)", value: "gtb"},
  // {label: "Zenith Bank", value: "zenith"},
  // {label: "Sterling Bank", value: "sterling"}
];

export const Wallets = ({ title, setTranId, tranId, transaction }) => {
  const [availableAccounts, setAvailableAccounts] = useState([]);
  const [searchParams] = useSearchParams();
  const [userAuth, setUserAuth] = useState(null);
  const [qrImage, setQrImage] = useState();
  const [tabs, setTabs] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [pin, setPin] = useState(null);
  // const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);
  // const [tranId, setTranId] = useState(null);
  const [cover, setCover] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [secondToExpire, setSecondToExpire] = useState(300);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOTP] = useState("");
  const isContest = searchParams.get("isContest");
  const [isPersonal, setIsPersonal] = useState(true);
  const [pWarning, setPwarnin] = useState('');

  useEffect(() => {
    const storage = JSON.parse(sessionStorage.getItem("product_data"));
    const tranId = sessionStorage.getItem("tranId");
    setTranId(tranId);
    // setTransaction(storage);

    if (sessionStorage.getItem("expired")) {
      const fallbackUrl = sessionStorage.getItem("fallbackUrl");
      //console.log(fallbackUrl);
      sessionStorage.clear();
      if (typeof(fallbackUrl) !== 'undefined' && fallbackUrl != null){
        window.location.href= fallbackUrl;
      }
      else{
        window.history.go(-1);
      }
    }
  }, [transaction]);

  const dropdown = useRef();
  const handleClick = (tabIndex) => {
    setTabs(tabIndex);
  };
  const toggleDropdown = () => {
    dropdown.current.classList.toggle("hidden");
  };
  const selectWallet = (walletIndex) => {
    setWallet(parseInt(walletIndex));
    toggleDropdown();
  };
  const generateQRCode = () => {
    // toast("Feature coming soon.", {
    //   backgroundColor: '#FF6700',
    //   color: '#ffffff',
    // });
    setLoading(true);
    const product_details = JSON.parse(sessionStorage.getItem("product_data"));
    const now = new Date();
    let qrExpiryDate = new Date(now.setMinutes(now.getMinutes() + 300));
    let refNo = tranId;
    const paymentData = {
      qrExpiryDate: qrExpiryDate,
      refNo: refNo,
    };
    const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/generate/qr-code`;
    axios
      .post(API_ENDPOINT, paymentData)
      .then((response) => {
        setQrImage(response.data.data.body);
        setLoading(false);
        handleClick(2);
        // trand id has expired
        sessionStorage.setItem("expired", true);
        sessionStorage.setItem("expiryDate", Date.now());
        countdownHandler();

        setCover(true);
        setTimeout(() => {
          setTabs(0);
          // const fallbackUrl= sessionStorage.getItem("fallbackUrl");
          // window.sessionStorage.clear();
          // window.location.href = fallbackUrl;
        }, 300000);

        setError(null);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.message);
      });
  };

  useEffect(() => {
    var _navigator = {};
    for (var i in navigator) _navigator[i] = navigator[i];
    setSystemInfo(() => _navigator);
  }, []);

  const generateOTP = async () => {
    try {
      setLoading(true);
      const res = await authOTP(email);
      toast.success("Check your email to verify OTP");
      setOtpModal(true);
      setLoading(false);
    } catch (err) {
      // err =err?.response?.data
      setLoading(false);
      //console.log(err?.message, 'IN GEN_OTP');
      if (err && err.message.includes("2FA not active for this user")) {
        return loginHandler();
      }
      toast.error(err.message || "An error occured!");
    }
  };

  const loginHandler = () => {
    setLoading(true);
    const loginData = {
      emailOrPhoneNumber: email,
      password: password,
      otp
    };
    if (email && password) {
      const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/authentication/wallet`;
      axios
        .post(API_ENDPOINT, loginData,
          {
            headers: {
              'CLIENT-TYPE': localStorage.getItem('clientType') ?? 'PERSONAL',
              'CLIENT-ID': 'WAYAQUICK',
            }
          }
          )
        .then((response) => {
          toast.success("Auth successfully");
          setUserAuth(response.data.data.token);
          setAvailableAccounts(response.data.data.wallet);
          setError(null);
          setLoading(false);
          setTabs(4);
          setOtpModal(false);
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || "An error occured!");
          setError("Error Occured " + error?.response?.data?.message);
          setLoading(false);
          setOtpModal(false);
        });
    } else {
      setOtpModal(false);
      setError("All fields are required!");
      setLoading(false);
    }
  };

  const payWithWallet = () => {
    setLoading(true);
    // const tranId = tranId;
    if (pin && availableAccounts && tranId) {
      const walletData = {
        accountNo: availableAccounts[wallet].accountNo,
        pin: pin,
        refNo: tranId,
        deviceInformation: JSON.stringify(systemInfo),
      };
      const header = {
        headers: {
          Authorization: userAuth,
          'CLIENT-TYPE': localStorage.getItem('clientType') ?? 'PERSONAL',
          'CLIENT-ID': 'WAYAQUICK'
        },
      };
      setError(null);
      // *****************

      const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/wallet/payment`;
      axios
        .post(API_ENDPOINT, walletData, header)
        .then((response) => {
          if (!response.data.status) {
            setLoading(false);
            setError(response.data.message);
          } else {
            // Payment done
            sessionStorage.setItem("expired", true);

            setLoading(false);
            setCover(true);
            setTabs(5);
            setError(null);
            // setTimeout(() => {
            //   const fallbackUrl= sessionStorage.getItem("fallbackUrl");
            //   window.sessionStorage.clear();
            //   window.location.href = fallbackUrl;
            // }, 3000);

            // // const tranId = tranId;
            // const payload = {
            //   status: "SUCCESSFUL"
            // }
            // const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/status/${tranId}`;
            // axios.put(ENDPOINT, payload).then(response => {
            //   // //console.log(response);
            // });
          }
        })
        .catch((error) => {
          setLoading(false);
          // const tranId = tranId;
          const payload = {
            status: "FAILED",
          };
          const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/status/${tranId}`;
          axios.put(ENDPOINT, payload).then((response) => {
            // //console.log(response);
          });
          setError("Error Occured " + error.response?.data?.message);
        });
    } else {
      setError("Pin field is required. Kindly fill it.");
      setLoading(false);
    }
  };

  const closePopup = () => {
    setTabs(0);
    const callback = window.sessionStorage.getItem("fallbackUrl");
    // sessionStorage.clear();
    if (typeof(callback) !== 'undefined' && callback != null){
      window.location.href= callback;
    }
    else{
      window.history.go(-1);
    }
  };

  const countdownHandler = () => {
    const intervalFxn = setInterval(() => {
      const remainingTime =
        300 -
        Math.floor(
          Math.abs((Date.now() - sessionStorage.getItem("expiryDate")) / 1000)
        );
      // //console.log(remainingTime);
      setSecondToExpire(remainingTime);
      if (remainingTime < 0) {
        // alert(0);
        clearInterval(intervalFxn);
      }
      setSecondToExpire(remainingTime);
    }, 1000);
  };
  const validateInput = (val) => {
    setPwarnin('');
    const phoneValidation = isPersonal ? /^([\s()-]*\d[\s()-]*){13}$/ : /^([\s()-]*\d[\s()-]*){12}/;
    const mailValidation = isPersonal ?
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ : /^([\s()-]*\d[\s()-]*){12}/;

    if (val.match(phoneValidation)) {
      // correct phone structure
      setPwarnin('');
      return true;
    }
    if (val.match(mailValidation)) {
      // correct mail format
      setPwarnin('');
      return true;
    }
    if (val?.length > 0)
      setPwarnin(
        isPersonal ? 'Please enter a valid email address or phone number' : 'Please enter a valid Business Id'
      );
    // incorrect structure
    return false;
  };
  return (
    <>
    <ContentsContainer>
      <ToastContainer position="top-right" delay="5000" />
      {cover && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100vh",
            backgroundColor: "transparent",
            left: 0,
            top: 0,
            zIndex: "200",
          }}
        ></div>
      )}
      {tabs == 0 && (
        <div className={styles.contents}>
          <div style={{ paddingTop: "24px" }}>
            <svg
              width="93"
              height="88"
              viewBox="0 0 93 88"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M67.0302 58.3062H64.302V38.3215H70.9999L46 19.9863L21 38.3215H27.6979V58.3062H24.9697C23.3883 58.3062 22.1019 59.5925 22.1019 61.1739V68.0119H69.8979V61.1739C69.898 59.5925 68.6116 58.3062 67.0302 58.3062ZM26.8391 36.4097L46 22.3575L65.1609 36.4097H26.8391ZM62.3902 38.3215V58.3062H57.3637V38.3215H62.3902ZM55.4519 38.3215V58.3062H50.4254V38.3215H55.4519ZM48.5134 38.3215V58.3062H43.4869V38.3215H48.5134ZM41.5751 38.3215V58.3062H36.5481V38.3215H41.5751ZM34.6363 38.3215V58.3062H29.6098V38.3215H34.6363ZM67.9861 66.1001H24.0138V61.174C24.0138 60.6465 24.4427 60.2181 24.9697 60.2181H67.0302C67.5572 60.2181 67.9861 60.6466 67.9861 61.174V66.1001Z"
                fill="#FF6700"
              />
              <path
                d="M46.0002 26.7988C43.848 26.7988 42.0967 28.5379 42.0967 30.6748C42.0967 32.8116 43.8479 34.5507 46.0002 34.5507C48.1525 34.5507 49.9037 32.8116 49.9037 30.6748C49.9037 28.538 48.1524 26.7988 46.0002 26.7988ZM46.0002 32.6389C44.9019 32.6389 44.0086 31.7577 44.0086 30.6748C44.0086 29.5919 44.902 28.7106 46.0002 28.7106C47.0985 28.7106 47.9918 29.5919 47.9918 30.6748C47.9918 31.7577 47.0984 32.6389 46.0002 32.6389Z"
                fill="#FF6700"
              />
              <ellipse
                cx="46.5"
                cy="44"
                rx="46.5"
                ry="44"
                fill="#FF6700"
                fillOpacity="0.3"
              />
            </svg>

            <p style={{ marginBottom: "24px" }}>
              Select your account provider to make payment
            </p>

            <Select
              style={{ textAlign: "left" }}
              options={supportedAccounts}
              placeholder={
                <p style={{ margin: "0", textAlign: "left" }}>
                  Click here to select account provider
                </p>
              }
              onChange={(e) => setSelectedAccount(e.value)}
            />

            <button
              onClick={() => {
                if (selectedAccount) {
                  if (selectedAccount === "wayabank") {
                    setTabs(1);
                  } else {
                    toast("Coming soon");
                  }
                } else {
                  toast("Select one account");
                }
              }}
              style={{
                backgroundColor: "#f40",
                padding: "12px 18px",
                borderRadius: "6px",
                width: "100%",
                border: "none",
                color: "#FFFFFF",
                marginTop: "24px",
              }}
            >
              Confirm account
            </button>
          </div>
        </div>
      )}
      {selectedAccount === "wayabank" && tabs == 1 && (
        <div className={styles.contents}>
          <PayableLogin
            tabIndex="3"
            onclick={handleClick}
            title="Login to Pay"
            description={`Click here to login or register account with wayapaychat and complete payment`}
          />
          {!isContest &&
            <PayableScan
              loading={loading}
              tabIndex="2"
              onclick={generateQRCode}
              title="Scan to Pay"
              description={`Kindly click here to generate QR Code for your payment`}
            />
          }
        </div>
      )}
      {selectedAccount === "wayabank" && tabs == 2 && (
        <div className={styles.contents2}>
          <BoldText align="center">
            Scan the generated QR Code below using your Wayapaychat Mobile App
            to complete the payment.
          </BoldText>
          <div className={styles.qr_container}>
            <img
              src={qrImage ? `data:image/png;base64, ${qrImage}` : qr_code}
              width="250"
              height="250"
              alt=""
            />
            <p>
              <img src={qrPhoneIcon} alt="" /> Scan Me
            </p>

            <h5 style={{ color: "white" }}>
              {convertTime(secondToExpire)} seconds to expire
            </h5>
          </div>
        </div>
      )}
      {selectedAccount === "wayabank" && tabs == 3 && (
        <div className={styles.contents2}>
          <img src={WayaBankLogo} width='150px'/>
          <br />
          <BoldText align="center">
            Kindly provide your wayabank login credentials to make payment
          </BoldText>
          <br />
          <h1 className="modal-header-data-log">Login as a</h1>
          
          <div className="row m-0 mb-5" style={{ display: 'flex', flexDirection: 'row'}}>
            <div className="col reg-active btn" 
            onClick={() => {
              localStorage.setItem('clientType', 'PERSONAL');
              setIsPersonal(true)
            }}
            style={{borderRadius: 5, padding:5, textAlign: 'center', background: isPersonal ? '#FF6700': ''}}>
              Personal
            </div>
            <div className="col reg-active text-centre btn"
              onClick={() => {
                localStorage.setItem('clientType', 'CORPORATE');
                setIsPersonal(false);
              }}
              style={{borderRadius: 5, padding: 5, textAlign: 'center', background: !isPersonal ? '#FF6700': ''}}>
              Corporate
            </div>
          </div>
          <br />
          <input
            type="text"
            onInput={(e) => setEmail(e.target.value)}
            placeholder={isPersonal ? `Email or Phone (2348020000000)` : `Business ID`}
            onPointerOut={(e) => validateInput(e.target.value)}
            className={styles.inputText}
          />
           <span className="text-danger" style={{textAlign:'left', color: 'red'}}>
            {pWarning}
          </span>
            <input
              type="password"
              onInput={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className={styles.inputText}
            />
          {error && tabs == "3" && <p className={styles.error}>{error}</p>}
          <button
            className={styles.continueBtn}
            onClick={generateOTP}
            disabled={loading}
          >
            {loading ? <Spinner /> : "continue"}
          </button>
        </div>
      )}
      {selectedAccount === "wayabank" && tabs == 4 && (
        <div className={styles.contents2}>
          <svg
            width="98"
            height="98"
            viewBox="0 0 98 98"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="49" cy="49" r="49" fill="#FF6700" fill-opacity="0.1" />
            <path
              d="M36.3084 26.3754L32.3641 22.4154C25.7442 27.4831 21.3862 35.2923 21 44.1538H26.5166C26.9303 36.8154 30.6815 30.3908 36.3084 26.3754ZM70.4835 44.1538H76C75.5863 35.2923 71.2282 27.4831 64.6359 22.4154L60.7192 26.3754C66.2909 30.3908 70.0697 36.8154 70.4835 44.1538ZM65.0497 45.5385C65.0497 37.0369 60.5261 29.92 52.6374 28.0369V26.1538C52.6374 23.8554 50.7894 22 48.5 22C46.2106 22 44.3626 23.8554 44.3626 26.1538V28.0369C36.4463 29.92 31.9504 37.0092 31.9504 45.5385V59.3846L26.4338 64.9231V67.6923H70.5662V64.9231L65.0497 59.3846V45.5385ZM48.5 76C48.8862 76 49.2447 75.9723 49.6033 75.8892C51.3962 75.5015 52.8581 74.2831 53.5752 72.6215C53.8511 71.9569 53.989 71.2369 53.989 70.4615H42.9559C42.9835 73.5077 45.4383 76 48.5 76Z"
              fill="#FF6700"
            />
          </svg>
          <br />
          <BoldText align="center">
            You are about send money to {transaction?.merchant_business_name} for{" "}
            {transaction?.product_name}
          </BoldText>
          <br />
          <div className={styles.w_full}>
            <div className="transaction-details">
              <p className={styles.summaryItem}>
                <span>Amount:</span>
                <span>NGN {transaction?.amount}</span>
              </p>
              <p className={styles.summaryItem}>
                <span>Fee:</span>
                <span>NGN {transaction?.fee}</span>
              </p>
              <p className={styles.summaryItem}>
                <span>
                  <b>Total Amount:</b>
                </span>
                <span>
                  <b>
                    NGN{" "}
                    {transaction?.fee +
                      transaction?.amount}
                  </b>
                </span>
              </p>
            </div>
          </div>
          <br />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <span style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <svg
                width="53"
                height="47"
                viewBox="0 0 53 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="53"
                  height="47"
                  rx="10"
                  fill="#FF6634"
                  fill-opacity="0.2"
                />
                <path
                  d="M37.25 30V31C37.25 32.1 36.125 33 34.75 33H17.25C15.8625 33 14.75 32.1 14.75 31V17C14.75 15.9 15.8625 15 17.25 15H34.75C36.125 15 37.25 15.9 37.25 17V18H26C24.6125 18 23.5 18.9 23.5 20V28C23.5 29.1 24.6125 30 26 30H37.25ZM26 28H38.5V20H26V28ZM31 25.5C29.9625 25.5 29.125 24.83 29.125 24C29.125 23.17 29.9625 22.5 31 22.5C32.0375 22.5 32.875 23.17 32.875 24C32.875 24.83 32.0375 25.5 31 25.5Z"
                  fill="#FF6700"
                />
              </svg>
              Account Balance
            </span>
            <span className={styles.accountBalance}>
              NGN {availableAccounts[wallet].clr_bal_amt}
            </span>
          </div>
          <div className={styles.selectAccount}>
            <span>
              <img src={WayaBankLogo} width="54px" />
            </span>
            <div className={styles.accountDesc}>
              <h5>{availableAccounts[wallet].acct_name}</h5>
              <p>{availableAccounts[wallet].accountNo}</p>
            </div>
            <svg
              onClick={toggleDropdown}
              style={{
                position: "absolute",
                top: "32px",
                right: "24px",
                cursor: "pointer",
              }}
              width="18"
              height="10"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L9 9L17 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <ul ref={dropdown} className={`${styles.dropdown} hidden`}>
              {availableAccounts.map((account, index) => (
                <li
                  key={index}
                  onClick={() => selectWallet(index)}
                  className={styles.dropdownItem}
                >
                  <div>
                    <h5>{account.acct_name}</h5>
                    <p>{account.accountNo}</p>
                  </div>
                  <h5>{account.clrBalAmt}</h5>
                </li>
              ))}
            </ul>
          </div>
          <input
            type="password"
            onInput={(e) => setPin(e.target.value)}
            placeholder="Enter your wallet pin"
            className={styles.pinInput}
          />

          {error && tabs == 4 && <p className={styles.error}>{error}</p>}

          <button
            className={styles.continueBtn}
            onClick={payWithWallet}
            disabled={loading}
          >
            {loading ? <Spinner /> : "continue"}
          </button>
        </div>
      )}
      {tabs == 5 && (
        <div className={styles.contents2}>
          <div>
            <svg
              width="100"
              height="100"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="75" cy="75" r="75" fill="#F1F7FA" />
              <path
                d="M49.0354 72.6442L68.6258 94.6015L103.402 59.2685"
                stroke="#27AE60"
                stroke-width="6"
              />
            </svg>
          </div>
          <h5 style={{ fontSize: 24, fontWeight: 600, margin: 0 }}>
            Payment made successfully.
          </h5>
          <h5 style={{ fontSize: 24, fontWeight: 600, margin: 0 }}>
            Transaction ID: {tranId}
          </h5>
          <h5 style={{ fontSize: 24, fontWeight: 600, margin: 0 }}>
            Amount: {transaction?.amount}
          </h5>
          <p className={styles.text_sm}>
            Your payment was completed successfully{" "}
          </p>

          <button className={styles.continueBtn} onClick={closePopup}>
            continue
          </button>
        </div>
      )}
    </ContentsContainer>
      {otpModal && <ModalContainer
        open={otpModal}
        oncancel={() => setOtpModal(false)}
        aria-labelledby="verify otp modal"
        aria-describedby="input otp from email"
      >
        <div className={styles.otpModal}>
          <h2>Enter OTP</h2>
          <input
            label="OTP"
            name="otp"
            placeholder="Enter OTP"
            value={otp}
            className={styles.pinInput}
            onChange={(e) => setOTP(e.target.value)}
            required={true}
          />

          <Button
            text="Log In"
            onClick={loginHandler}
            loading={loading}
          />
        </div>
      </ModalContainer>}
    </>
  );
};
