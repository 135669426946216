import React, { useState, useEffect } from "react";
import {
  CardContainer,
  SideCardContainer,
  SideCardcontent,
} from "../all-containers/general";
import { Background } from "../background/background";
import { CardContents } from "../card/cardcontainer";
import { SectionItem } from "../section-item/sectionItem";
import styles from "./container.module.css";
import { Title } from "../titles/titles";
import { Ussd } from "../ussd/ussd";
import { Bank } from "../bank/bank";
import { Wallets } from "../wallet/wallet";
import { PayAttitude } from "../payAttitude/pay";
import { ModalContainer } from "../modalContainer/container";
import {
  InProccess,
  OTPOverlay,
  PaymentFailed,
  PaymentSuccess,
  PlsWait,
} from "../modal/container";
import { SVGS } from "../../assets/svgs";
import { NormalText } from "../normalText/normalText";
import { useDispatch, useSelector } from "react-redux";
import { Dispatach_New_Tab } from "../../redux/actions/account";
import { useSearchParams, useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import config from '../../config.js';
// let CryptoJS = require("crypto-js");
import logo3 from '../../assets/quickLogo.png'


const {
  Ussd: USSD,
  bankIcon,
  credit_card,
  wallet,
  call_button,
  cardSect,
  // logo3,
  backLogo
} = SVGS;


export const Container = () => {
  const navigate = useNavigate();
  const { isContest } = useParams();
  // const [active, setActive] = useState("Card");
  const [modals, setModals] = useState("");
  const [tabs, setTabs] = useState(1);
  const active = useSelector(state => state.tabs)
  const Dispatch = useDispatch();
  const [companyName, setCompanyName] = useState(null);
  const [menus, setMenus] = useState([]);
  const [searchParams] = useSearchParams();
  const [tranIdd, setTranId] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState({});

  useEffect(() => {
    // if (searchParams.get("isContest")) {
    //   Dispatach_New_Tab(Dispatch, 'Account');
    // }
    if (sessionStorage.getItem("product_data")) {
      setCompanyName(JSON.parse(sessionStorage.getItem("product_data")).merchant_business_name);
    }

    const tranId1 = searchParams.get("_tranId");
    const tranId2 = sessionStorage.getItem("tranId");
    let merchantId;
    if (JSON.parse(sessionStorage.getItem("product_data"))) {
      merchantId = JSON.parse(sessionStorage.getItem("product_data"))?.customer?.merchantId;
    }
    let paymentOptionsList = [];

    axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/configuration/payment-preference?merchantId=${merchantId}`).then((result) => {
      const paymentOption = result.data.data;
      if (paymentOption.payWithCardEnabled) {
        paymentOptionsList.push({
          title: "Card",
          description: "Mastercard, Verve, Visa",
          img: credit_card,
        });
      } if (paymentOption.payWithUSSDEnabled) {
        paymentOptionsList.push({
          title: "USSD",
          description: "Pay with USSD code via your mobile device",
          img: USSD,
        });
      } if (paymentOption.payWithWalletEnabled) {
        paymentOptionsList.push({
          title: "Account",
          description: "Pay from your account",
          img: wallet,
        });
      }
      // if(paymentOption.payWithBankEnabled){
      //   paymentOptionsList.push({
      //   title: "Bank",
      //   description: "Make payment from your bank account",
      //   img: bankIcon,
      // })
      if (paymentOption.payWithPayAttitudeEnabled) {
        paymentOptionsList.push({
          title: "PayAttitude",
          description: "Pay with your phone number",
          img: call_button,
        })
      }
      // ****************
      // Extract from the url if customParam is part of the options/params
      const linkName = window?.location?.href?.split("?")[1]?.split("=")[0];
      const transactionSource = JSON.parse(window.sessionStorage.getItem("transactionSource"));
      // Link name will be undefined at first initiation of transaction, subsequent refreshes will bring have value
      if (transactionSource?.value === "linkPaymentPage") {
        if (transactionSource?.type === "recurrent") {
          const menu = [
            {
              title: "Card",
              description: "Mastercard, Verve, Visa",
              img: credit_card,
            }
          ]
          setMenus(menu);
        } else {
          setMenus(paymentOptionsList);
        }
      } else {
        // alert(transactionSource?.value);
        setMenus(paymentOptionsList);
      }
    }).catch((err) => {
      //console.log(err);
    });


    if (tranId1) {
      const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/reference/query/${tranId1}`;
      axios.get(ENDPOINT).then(response => {
        //console.log(response);
        if (response.data.data.Status !== "PENDING") {
          // clear session and redirect to demo page
          const merchantId = response?.data?.data.merchantId;
          if (merchantId) {
            axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/fetch/callback?param=${merchantId}`).then((result) => {
              const callback = result.data.data.webCallbackUrl;
              sessionStorage.clear();
              if (typeof(callback) !== 'undefined' && callback != null){
                window.location.href= callback;
              }
              else{
                if (sessionStorage.getItem('referrer')) {
                  window.location.href=sessionStorage.getItem('referrer');
                } else {
                  window.history.go(-1);
                }
              }
            }).catch((err) => {
              //console.log(err)
            });
          } else {
            if (sessionStorage.getItem('referrer')) {
              window.location.href=sessionStorage.getItem('referrer');
            } else {
              window.history.go(-1);
            }
          }
        } else {
          let storage = sessionStorage;
          if (sessionStorage.getItem("transactionSource") === "demoPaymentPage" || !sessionStorage.getItem("transactionSource")) {
            // If it is from demoPage
            storage.setItem("fallbackUrl", document.referrer + '?ref=' + tranId1 );
          } else {
            // If it is payment link/subscription
            axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/fetch/callback?param=${merchantId}`).then((result) => {
              const callback = result.data.data.webCallbackUrl;
              storage.setItem("fallbackUrl", callback + '?ref=' + tranId1);
            }).catch((err) => {
              //console.log(err);
            });
          }
          storage.setItem("tranId", tranId1);
          setTranId(tranId1);
          const transactionDetails = {
            amount: response.data.data.Amount,
            description: response.data.data.Description,
            fee: response.data.data.Fee,
            customer: { name: response.data.data.customer.name, email: response.data.data.customer.email, phoneNumber: response.data.data.customer.phoneNumber, customerId: response.data.data.customer.customerId, merchantId: response.data.data.merchantId },
            product_name: response.data.data.productName,
            merchant_business_name: response.data.data.businessName,
            recurrentPayment: response?.data?.data?.recurrentPayment,
          }
          setTransactionDetails(transactionDetails);
          storage.setItem("product_data", JSON.stringify(transactionDetails));
          sessionStorage.setItem("PUBLIC_KEY", config.WAYA_PUB_KEY);
        }
      }).catch(err => {
        // clear session and redirect to demo page
        const callback = window.sessionStorage.getItem("fallbackUrl")
        // window.location.href= `${callback}?_msg=can't_validate_transaction_ID`;
      })
    } else if (tranId2) {
      const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/reference/query/${tranId2}`;
      axios.get(ENDPOINT).then(response => {
        //console.log(response);
        if (response.data.data.Status === "PENDING") {
          // append the value to url query string
          navigate(`/?_tranId=${tranId2}`);
        } else {
          // clear session and redirect to demo page
          const callback = window.sessionStorage.getItem("fallbackUrl")
          sessionStorage.clear();
          if (typeof(callback) !== 'undefined' && callback != null){
            window.location.href= callback;
          }
          else{
            window.history.go(-1);
          }
        }
      }).catch(err => {
        //console.log(err);
        // clear session and redirect to demo page
        const callback = window.sessionStorage.getItem("fallbackUrl")
        sessionStorage.clear();
        if (typeof(callback) !== 'undefined' && callback != null){
          window.location.href= callback;
        }
        else{
          window.history.go(-1);
        }
        
      })
    } else {
      // sessionStorage.clear();
      const callback = window.sessionStorage.getItem("fallbackUrl")
      sessionStorage.clear();
      if (typeof(callback) !== 'undefined' && callback != null){
        window.location.href= callback;
      }
      else{
        window.history.go(-1);
      }
      // navigate(`/demo/?_msg=transaction_ID_must_be_provided`);
    }

  }, [tranIdd]);

  useEffect(() => {

    if (sessionStorage.getItem("product_data")) {
      setCompanyName(JSON.parse(sessionStorage.getItem("product_data")).merchant_business_name);
    }

    const tranId1 = searchParams.get("_tranId");
    const tranId2 = sessionStorage.getItem("tranId");
    let merchantId;
    if (JSON.parse(sessionStorage.getItem("product_data"))) {
      merchantId = JSON.parse(sessionStorage.getItem("product_data"))?.customer?.merchantId;
    }
    let paymentOptionsList = [];

    axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/configuration/payment-preference?merchantId=${merchantId}`).then((result) => {
      const paymentOption = result.data.data;
      if (paymentOption.payWithCardEnabled) {
        paymentOptionsList.push({
          title: "Card",
          description: "Mastercard, Verve, Visa",
          img: credit_card,
        });
      } if (paymentOption.payWithUSSDEnabled) {
        paymentOptionsList.push({
          title: "USSD",
          description: "Pay with USSD code via your mobile device",
          img: USSD,
        });
      } if (paymentOption.payWithWalletEnabled) {
        paymentOptionsList.push({
          title: "Account",
          description: "Pay from your account",
          img: wallet,
        });
      }
      // if(paymentOption.payWithBankEnabled){
      //   paymentOptionsList.push({
      //   title: "Bank",
      //   description: "Make payment from your bank account",
      //   img: bankIcon,
      // })
      if (paymentOption.payWithPayAttitudeEnabled) {
        paymentOptionsList.push({
          title: "PayAttitude",
          description: "Pay with your phone number",
          img: call_button,
        })
      }
      // ****************
      // Extract from the url if customParam is part of the options/params
      const linkName = window?.location?.href?.split("?")[1]?.split("=")[0];
      const transactionSource = JSON.parse(window.sessionStorage.getItem("transactionSource"));
      // Link name will be undefined at first initiation of transaction, subsequent refreshes will bring have value
      if (transactionSource?.value === "linkPaymentPage") {
        if (transactionSource?.type === "recurrent") {
          const menu = [
            {
              title: "Card",
              description: "Mastercard, Verve, Visa",
              img: credit_card,
            }
          ]
          setMenus(menu);
        } else {
          setMenus(paymentOptionsList);
        }
      } else {
        // alert(transactionSource?.value);
        setMenus(paymentOptionsList);
      }
    }).catch((err) => {
      //console.log(err);
    });



    if (tranId1) {
      const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/reference/query/${tranId1}`;
      axios.get(ENDPOINT).then(response => {
        //console.log(response);
        if (response.data.data.Status !== "PENDING") {
          // clear session and redirect to demo page
          const merchantId = response.data.data.merchantId;
          if (merchantId) {
            axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/fetch/callback?param=${merchantId}`).then((result) => {
              const callback = result.data.data.webCallbackUrl;
              sessionStorage.clear();
              if (typeof(callback) !== 'undefined' && callback != null){
                window.location.href= callback;
              }
              else{
                window.history.go(-1);
              }
            }).catch((err) => {
              //console.log(err)
            });
          } else {
              window.history.go(-1);
          }
        } else {
          let storage = sessionStorage;
          if (sessionStorage.getItem("transactionSource") === "demoPaymentPage" || !sessionStorage.getItem("transactionSource")) {
            // If it is from demoPage
            storage.setItem("fallbackUrl", document.referrer + '?ref=' + tranId1 );
          } else {
            // If it is payment link/subscription
            axios.get(`${config.API_ROOT}/identity-manager-service/api/v1/waya-merchant/fetch/callback?param=${merchantId}`).then((result) => {
              const callback = result.data.data.webCallbackUrl;
              storage.setItem("fallbackUrl", callback + '?ref=' + tranId1);
            }).catch((err) => {
              //console.log(err);
            });
          }
          storage.setItem("tranId", tranId1);
          //console.log('trannnnnnhjjj',tranId1);
          storage.setItem("product_data", JSON.stringify({
            amount: response.data.data.Amount,
            description: response.data.data.Description,
            fee: response.data.data.Fee,
            customer: { name: response.data.data.customer.name, email: response.data.data.customer.email, phoneNumber: response.data.data.customer.phoneNumber, customerId: response.data.data.customer.customerId, merchantId: response.data.data.merchantId },
            product_name: response.data.data.productName,
            merchant_business_name: response.data.data.businessName,
            recurrentPayment: response?.data?.data?.recurrentPayment,
          }));
          sessionStorage.setItem("PUBLIC_KEY", config.WAYA_PUB_KEY);
        }
      }).catch(err => {
        // clear session and redirect to demo page
        const callback = window.sessionStorage.getItem("fallbackUrl")
        // window.location.href= `${callback}?_msg=can't_validate_transaction_ID`;
      })
    } else if (tranId2) {
      const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/reference/query/${tranId2}`;
      axios.get(ENDPOINT).then(response => {
        //console.log(response);
        if (response.data.data.Status === "PENDING") {
          // append the value to url query string
          navigate(`/?_tranId=${tranId2}`);
        } else {
          // clear session and redirect to demo page
          const callback = window.sessionStorage.getItem("fallbackUrl")
          sessionStorage.clear();
          if (typeof(callback) !== 'undefined' && callback != null){
            window.location.href= callback;
          }
          else{
            window.history.go(-1);
          }
        }
      }).catch(err => {
        //console.log(err);
        // clear session and redirect to demo page
        const callback = window.sessionStorage.getItem("fallbackUrl")
        sessionStorage.clear();
        if (typeof(callback) !== 'undefined' && callback != null){
          window.location.href= callback;
        }
        else{
          window.history.go(-1);
        }
      })
    } else {
      const callback = window.sessionStorage.getItem("fallbackUrl")
      sessionStorage.clear(); 
      if (typeof(callback) !== 'undefined' && callback != null){
        window.location.href= callback;
      }
      else{
        window.history.go(-1);
      }
      // navigate(`/demo/?_msg=transaction_ID_must_be_provided`);
    }
  }, [tranIdd, transactionDetails])

  // //console.log(tabsState)
  const handleTabChange = (value) => {
    Dispatach_New_Tab(Dispatch, value)
  }

  const renderItems = () => {
    if (active === "Card") {
      return <CardContents modals={setModals} overTabs={tabs} />;
    } else if (active === "USSD") {
      return <Ussd />;
    } else if (active === "Bank") {
      return <Bank />;
    } else if (active === "Account") {
      return <Wallets setTranId={setTranId} tranId={tranIdd} transaction={transactionDetails} />;
    } else if (active === "PayAttitude") {
      return <PayAttitude />;
    }
  };

  const cancelPageHandler = async () => {
    const fallbackUrl = await sessionStorage.getItem("fallbackUrl");
    const tranId = await sessionStorage.getItem("tranId");
    const payload = {
      status: "ABANDONED"
    }
    const ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/abandon/${tranId}`;
    if (tranId) {
      axios.put(ENDPOINT, payload).then(response => {
        sessionStorage.clear();
        if (typeof(fallbackUrl) !== 'undefined' && fallbackUrl != null){
          window.location.href= fallbackUrl;
        }
        else{
          window.history.go(-1);
        }
      });
    } else {
      if (typeof(fallbackUrl) !== 'undefined' && fallbackUrl != null){
        window.location.href= fallbackUrl;
      }
      else{
        window.history.go(-1);
      }
    }
  }

  return (
    <Background>
      <div className={`${styles.container}`}>
        <div className={`${styles.controls}`}>
          <div className={styles.logs}>
            {/* <div className={styles.width}>
            <img src={backLogo} alt="" height={'20'} width="20" />
            </div> */}
            <div className={styles.logostyles}>
              <img src={logo3} alt="" width="150px" />
              <button onClick={() => cancelPageHandler()} className={styles.cancelBtn}>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.85571 6.5L13 11.6443V13H11.6443L6.5 7.85571L1.35571 13H0V11.6443L5.14429 6.5L0 1.35571V0H1.35571L6.5 5.14429L11.6443 0H13V1.35571L7.85571 6.5Z" fill="white" />
                </svg>
                Cancel
              </button>
            </div>
          </div>
        </div>
        <CardContainer>
          <div className={styles.secondOption}>
            {menus?.map((el) => (
              <div
                active={active}
                onClick={() => handleTabChange(el.title)}
                className={`${styles.eachMobileMenu} ${el.title === active && styles.active}`}
              >
                <img src={el.img} width="30" height="30" alt="" />
              </div>
            ))}
          </div>
          <SideCardContainer classStyles={styles.hidden} >
            <p style={{
              paddingLeft: '18px',
              marginBottom: 2,
              fontSize: '13px'
            }}>Make payment to:</p>

            <h5 style={{
              paddingLeft: '18px',
              textTransform: 'uppercase',
              marginTop: 4,
              fontSize: 16
            }}>{companyName}</h5>
            <Title size={15} title={active === 'Card' ? 'SELECT YOUR PREFERRED PAYMENT METHOD' : `SELECT PAYMENT METHOD`} />
            {menus?.map((el) => (
              <SectionItem
                title={`${el.title}`}
                description={`${el.description}`}
                active={active}
                setActive={handleTabChange}
              >
                <img src={el.img} width="30" height="30" alt="" />
              </SectionItem>
            ))}
          </SideCardContainer>
          <SideCardContainer>{renderItems()}</SideCardContainer>
        </CardContainer>
        <div className={`${styles.bottom}`}>
          <fieldset
            style={{ borderRadius: 10, borderColor: "var(--red)", padding: 10 }}
          >
            <legend align="center">Payment secured by WayaQuick</legend>
            <img src={cardSect} alt="" width="300" />
          </fieldset>
          <div style={{ width: "100%", marginTop: 14, marginBottom: 5 }}>
            <NormalText size={12} align="center">
              Accept, Process and Disburse Digital Payments for your business<br />
              <a href="https://wayapay.ng" target="_blank" style={{ color: 'red' }}> Learn
                More</a>
            </NormalText>
          </div>
        </div>
      </div>
      {/* <div className={`${styles.container}`}></div> */}

      {/* MODALS */}
      {modals === "proccess" && (
        <InProccess
          cancel={() => {
            setModals("");
            setTabs(1);
          }}
        />
      )}
      {modals === "otp" && (
        <OTPOverlay
          cancel={() => {
            setModals("");
            setTabs(1);
          }}
        />
      )}
      {modals === "success" && <PaymentSuccess />}
      {modals === "failed" && <PaymentFailed />}
      {modals === "wait" && <PlsWait />}
    </Background>
  );
};
