import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Success from '../components/success/success';
import axios from 'axios';
import Failed from '../components/failed/failed';
import config from '../config.js';

function TransactionAuth({ children }) {
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const tranId = sessionStorage.getItem("tranId");

        const API_ENDPOINT = `${config.API_ROOT}/payment-gateway/api/v1/reference/query/${tranId}`;

        if (tranId) {
            axios.get(API_ENDPOINT).then(response => {
                setIsAuth(response.data.data.Status);
                if(response.data.data.Status !== "SUCCESSFUL" && response.data.data.Status !== "FAILED"){
                    // navigate("/demo?msg=Status");
                }
                //  console.log(response);
            }).catch(err => {
                setIsAuth("NOT_DONE");
                //  console.log(err.response)
            });
        } else {
            navigate("/demo?msg=You_cannot_manually_access_this_page.");
            // alert("Tran Id not available")
        }
    }, []);

    return (
        <React.Fragment>
            {
                isAuth === "SUCCESSFUL" ? (
                    <Success />
                ) : <Failed />
            }
            {
                // isAuth === "FAILED" || isAuth === "DECLINED"  &&  (
                // )
            }{
                // isAuth !== "SUCCESSFUL" && isAuth !== "FAILED" && (
                //     <Navigate to="/demo" />
                // )
            }
        </React.Fragment>
    )
}

export default TransactionAuth;
