import React from "react";
import styles from "./normal.module.css";

export const Prize = ({ children }) => {
  return <div>
      <p style={{marginBottom: "4px",fontSize: "13px",}}>Amount payable is</p>
      <div className={`${styles.text}`}>
        {children ? (
          <>
            <span className={styles.currency}>NGN</span> {children.toLocaleString()}
          </>
        ) : (
          ''
        )}
      </div>
  </div>;
};
